import http from 'hub-http/clients/apiClient';
import once from 'transmute/once';
import { reportNewTasksError } from '../tracking/errors';
/**
 * Fetches all teams in portal
 * @returns all teams
 */
export function fetchAllTeams() {
  return http.get('app-users/v1/teams');
}

/**
 * Fetches team by ID
 * @returns single team
 */
export function fetchTeamById({
  teamId
}) {
  return http.get(`app-users/v1/teams/${teamId}`);
}

/**
 * Fetches all teams current user belongs to
 * @returns all user's teams
 */
export function fetchAllUserTeams() {
  return http.get('app-users/v1/teams/user/all');
}
export const _getSecondaryTeamIds = teams => {
  return teams.map(({
    secondaryUserIds
  }) => secondaryUserIds.map(String)).reduce((accumulator, team) => {
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...accumulator, ...team];
  }, []);
};

// export individually for testing purposes
export const _fetchSecondaryTeamIds = () => {
  return fetchAllUserTeams().then(teams => {
    return _getSecondaryTeamIds(teams);
  }).catch(error => {
    reportNewTasksError(error);
    return [];
  });
};
export const fetchSecondaryTeamIdsOnce = once(_fetchSecondaryTeamIds);