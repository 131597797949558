import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import invariant from 'react-utils/invariant';
import getIn from 'transmute/getIn';
import has from 'transmute/has';
import memoize from 'transmute/memoize';
import { reverseKeysAndValues } from './reverseKeysAndValues';
import { VALID_ASSOCIATIONS } from '../formatters/associations';
const ASSOCIATION_TYPE_TO_OBJECT_TYPE = reverseKeysAndValues(ObjectTypesToIds);

// Expects associations in simple format
export function getObjectTypesAndIdsFromSingleAssociationsObject(taskAssociations) {
  return VALID_ASSOCIATIONS.reduce((objectInfoArray, associationType) => {
    if (!has(associationType)(taskAssociations)) {
      return objectInfoArray;
    }
    const objectInfoArrayAdditions = getIn([associationType, 'objectIds'], taskAssociations).map(objectId => ({
      type: ASSOCIATION_TYPE_TO_OBJECT_TYPE[associationType],
      id: objectId
    }));

    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...objectInfoArray, ...objectInfoArrayAdditions];
  }, []);
}
function makeObjectInfoArrayUnique(objectInfoArray) {
  return objectInfoArray.reduce((filteredArray, curObjectInfo) => {
    const foundObject = filteredArray.find(({
      id: searchObjectId,
      type: searchObjectType
    }) => curObjectInfo.id === searchObjectId && curObjectInfo.type === searchObjectType);
    if (foundObject) {
      return filteredArray;
    }

    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...filteredArray, curObjectInfo];
  }, []);
}
export const getObjectTypesAndIdsFromAssociations = memoize(tasks => {
  invariant(Array.isArray(tasks), 'Expected taskAssociationsArray to be an array,');
  const taskAssociationsArray = tasks.map(task => task.associations.toJS());
  const unfilteredObjectInfoArray = taskAssociationsArray.reduce((objectInfoArray, taskAssociations) => {
    return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...objectInfoArray, ...getObjectTypesAndIdsFromSingleAssociationsObject(taskAssociations)];
  }, []);
  return makeObjectInfoArrayUnique(unfilteredObjectInfoArray);
});