export function getLinkText(doc, markBounds) {
  const {
    from,
    to
  } = markBounds;
  return doc.textBetween(from, to);
}
export function getLinkAttrs(state, markBounds) {
  const {
    from
  } = markBounds;
  const node = state.doc.nodeAt(from);
  const linkMark = state.schema.marks.link;
  if (!node) return null;
  const mark = linkMark.isInSet(node.marks);
  return mark ? mark.attrs : null;
}
function checkSelectionInsideLink(state) {
  return state.schema.marks.link.isInSet(state.selection.$from.marks());
}
function checkSelectionAroundLink(state) {
  const {
    $from
  } = state.selection;
  const nodeAfter = $from.nodeAfter;
  const nodeBefore = $from.nodeBefore;
  let marks = nodeAfter ? nodeAfter.marks : [];
  marks = nodeBefore ? marks.concat(nodeBefore.marks) : marks;
  if ($from.textOffset === 0) {
    return state.schema.marks.link.isInSet(marks);
  }
  return undefined;
}
export function getActiveLinkMark(state) {
  return checkSelectionInsideLink(state) || checkSelectionAroundLink(state);
}
export function makeLinkNode(schema, href, linkText, isTargetBlank, noFollow, extraMarks = []) {
  return schema.text(linkText, schema.marks.link.create({
    href,
    title: linkText,
    isTargetBlank,
    rel: noFollow ? 'nofollow' : undefined
  }).addToSet(extraMarks));
}