import http from 'hub-http/clients/apiClient';
import { GROUP_NAMES, reportNewTasksError } from '../tracking/errors';
import allSettled from 'hs-promise-utils/allSettled';
const MAX_REMOTES_TO_FETCH = 100;
function getChunkedUserIds(userIds) {
  if (userIds.length <= 100) {
    return [userIds];
  }
  return userIds.reduce((chunks, userId, index) => {
    const chunkIndex = Math.floor(index / MAX_REMOTES_TO_FETCH);
    if (!chunks[chunkIndex]) {
      return [...chunks, [userId]];
    }

    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...chunks.slice(0, -1), [...chunks[chunkIndex], userId]];
  }, []);
}
export function fetchOwnersByUserIds(userIds) {
  const userIdChunks = getChunkedUserIds(userIds);
  const promises = userIdChunks.map(userIdChunk => http.post(`owners/v2/owners/batch-get/remotes/HUBSPOT`, {
    data: userIdChunk
  }).catch(error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.OWNERS,
      functionName: 'fetchOwnersByUserIds'
    });
  }));
  return allSettled(promises).then(settledPromises => settledPromises.reduce((ownersByUserId, settledPromise) => {
    if (settledPromise.status !== 'fulfilled') {
      return ownersByUserId;
    }
    return Object.assign({}, ownersByUserId, settledPromise.value);
  }, {}));
}