import { CRM_UI_BULK_ACTION } from 'customer-data-objects/property/PropertySourceTypes';
import http from 'hub-http/clients/apiClient';
import { fromJS, Map as ImmutableMap } from 'immutable';
import { engagementFromInboundDbObject, engagementToInboundDbObject } from '../utils/engagementInboundDbObjectHelpers';
import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { INVALID_BULK_EDIT_ERROR } from '../hooks/tasksBulkActionsHooks/tasksCrud';
// @ts-expect-error customer-data-objects is not typed
import { mapTaskAPIV2ResponseToTaskRecord } from 'customer-data-objects/task/mapTaskAPIResponseToTaskRecord';
import { DUE_DATE, REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
// copied from crm_data/engagements/api/EngagementsBatchAPI
export const makeChunks = (engagements, countPerChunk = 25) => {
  return engagements.reduce((acc, item, index) => {
    const chunkIndex = Math.floor(index / countPerChunk);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(item);
    return acc;
  }, []);
};

// copied from crm_data/engagements/api/EngagementsBatchAPI
const _transformEngagement = engagement => {
  const createdEngagement = engagementFromInboundDbObject(fromJS(engagement));
  return [createdEngagement.getIn(['engagement', 'id']), createdEngagement];
};
export const transformEngagements = resp => {
  const allEngagements = resp.reduce((acc, item) => {
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    acc = [...acc, ...item];
    return acc;
  }, []);
  return ImmutableMap(allEngagements.map(_transformEngagement));
};
export const fetchLinkedInSalesNavIntegrationStatus = linkedInSalesNavigatorId => {
  return http.post('/appinstalls/v3/app-install/native-app-connection-status', {
    data: {
      appIds: [linkedInSalesNavigatorId]
    }
  });
};
export const bulkFetchTasks = (taskIds, properties = []) => {
  return http.post('tasks/v2/tasks/batch/get', {
    data: {
      inputs: taskIds
    },
    query: {
      properties
    }
  }).then(response => {
    const results = response.hits;
    return Object.values(results).map(mapTaskAPIV2ResponseToTaskRecord);
  });
};
export const batchCreateTasks = engagements => {
  const inboundDbEngagements = engagements.map(engagementToInboundDbObject);
  const batchPromises = makeChunks(inboundDbEngagements).map(chunk => {
    return http.post('engagements/v2/engagements/batch', {
      data: chunk,
      headers: {
        'X-Properties-Source': CRM_UI_BULK_ACTION
      }
    });
  });
  return Promise.all(batchPromises).then(response => transformEngagements(response));
};
export const bulkUpdateTasks = ({
  taskIds,
  propertyUpdates,
  crmSearchRequestOptions
}) => {
  const taskIdentifierOption = crmSearchRequestOptions ? {
    crmSearchRequestProxyEgg: Object.assign({}, crmSearchRequestOptions, {
      objectTypeId: TASK_TYPE_ID
    })
  } : {
    objectIdList: taskIds || []
  };
  const hasInvalidData = propertyUpdates.some(({
    name,
    value
  }) => {
    return (name === REMINDERS || name === DUE_DATE) && value != null && Number.isNaN(parseInt(value, 10));
  });
  if (hasInvalidData) {
    const error = new Error();
    // @ts-expect-error adding extra property
    error.status = INVALID_BULK_EDIT_ERROR;
    return Promise.reject(error);
  }
  return http.post('inbounddb-objects/v2/batch-mutation/dynamic-search', {
    data: Object.assign({
      type: 'UPDATE',
      objectTypeId: TASK_TYPE_ID,
      properties: propertyUpdates
    }, taskIdentifierOption),
    headers: {
      'X-Properties-Source': CRM_UI_BULK_ACTION
    }
  });
};
export const bulkDeleteTasks = ({
  taskIds,
  crmSearchRequestOptions
}) => {
  const taskIdentifierOption = crmSearchRequestOptions ? {
    crmSearchRequestProxyEgg: Object.assign({}, crmSearchRequestOptions, {
      objectTypeId: TASK_TYPE_ID
    })
  } : {
    objectIdList: taskIds || []
  };
  return http.post('inbounddb-objects/v2/batch-mutation/dynamic-search', {
    data: Object.assign({
      type: 'DELETE',
      objectTypeId: TASK_TYPE_ID
    }, taskIdentifierOption),
    headers: {
      'X-Properties-Source': CRM_UI_BULK_ACTION
    }
  });
};