require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "crm-tasks-ui/lang/bundles/taskForm",
  context: {
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./da.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./de.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-de" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./en.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-en" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./es.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./fi.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-fi" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./fr.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-fr" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./it.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./ja.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./ko.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-ko" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./nl.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./no.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./pl.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./pt-br.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-pt-br" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./sv.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./th.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-th" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./zh-cn.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-zh-cn" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1470/node_modules/bender-lyaml-loader/lib/index.js??ref--17!./zh-tw.lyaml?sporks" /* webpackChunkName: "crm-tasks-ui-lang-bundles-taskForm-zh-tw" */); }  }
};
