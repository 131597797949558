import { Plugin, PluginKey } from 'prosemirror-state';
import { Metrics } from '../../Metrics';
import { hasFontSize, hasFontStyle, hasTextColor } from '../utils/fontUtils';
import { applyChildrenStylesToNode } from '../utils/listUtils';
/**
 * Reassign the storedMarks in the editor state if they are not overridden by
 * a selection or user input from the editor toolbar.
 */
const addMissingStoredMarks = function addMissingStoredMarks(view) {
  const {
    $cursor
  } = view.state.selection;
  if ($cursor && $cursor.node().content.size === 0) {
    let tr = view.state.tr;
    const pluginState = this.getState(view.state);
    const {
      schema
    } = view.state;
    if (!hasTextColor(view.state) && pluginState.text_color) {
      tr = tr.addStoredMark(schema.marks.text_color.create({
        color: pluginState.text_color
      }));
    }
    if (!hasFontSize(view.state) && pluginState.font_size) {
      tr = tr.addStoredMark(schema.marks.font_size.create({
        size: pluginState.font_size
      }));
    }
    if (!hasFontStyle(view.state) && pluginState.font_style) {
      tr = tr.addStoredMark(schema.marks.font_style.create({
        style: pluginState.font_style
      }));
    }
    view.dispatch(tr);
  }
  return false;
};
export const fontPluginKey = new PluginKey('FontPlugin');
export const createFontPlugin = defaultFontInfo => {
  const FontPluginInstance = new Plugin({
    key: fontPluginKey,
    appendTransaction: (trs, oldState, newState) => {
      return Metrics.timer('font_plugin_append_transaction').time(() => {
        const tr = newState.tr;
        applyChildrenStylesToNode(newState, newState.doc, tr, 'list_item');
        if (trs.some(t => t.docChanged)) {
          const fromSelectionSize = oldState.selection.$from.marks().find(mark => mark.type === newState.schema.marks.font_size);

          // Apply default font size and color on all descendents of the document
          // where a mark is not otherwise present.
          markFontSize(newState, tr, defaultFontInfo, fromSelectionSize);
        }
        return tr;
      });
    },
    state: {
      init() {
        return {
          defaultFontInfo,
          font_size: defaultFontInfo.usePlaintextMode ? undefined : defaultFontInfo.defaultFontSize
        };
      },
      apply(tr, old) {
        return Object.assign({}, old, {
          text_color: tr.getMeta('text_color') || old.text_color,
          font_size: tr.getMeta('font_size') || old.font_size,
          font_style: tr.getMeta('font_style') || old.font_style
        });
      }
    },
    props: {
      handlePaste: addMissingStoredMarks,
      handleKeyDown: addMissingStoredMarks
    }
  });
  return FontPluginInstance;
};

/**
 * Applies a default font size to all text nodes in the document that do not
 * already have a font size mark. Email reply history nodes are ignored.
 * The mark present on the editor's current selection should be provided as
 * the `fromSelectionSize` argument. If present, it will be used instead of
 * the default.
 */
export function markFontSize(editorState, tr, fontInfo, fromSelectionSize) {
  const {
    defaultFontSize,
    usePlaintextMode
  } = fontInfo;
  if (!defaultFontSize || usePlaintextMode) return;
  Metrics.timer('font_plugin_mark_font_size').time(() => {
    editorState.doc.descendants((node, pos) => {
      if (node.type.name === 'emailReplyHistory') return false;
      if (!node.isText || node.marks.some(mark => mark.type.name === 'font_size')) return;
      tr.addMark(pos, pos + node.nodeSize, fromSelectionSize || editorState.schema.marks.font_size.create({
        size: defaultFontSize
      })).setMeta('addToHistory', false);
    });
  });
}